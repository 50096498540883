export const AllPlatforms = ({ className }: { className?: string }) => (
    <svg
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            id="Vector"
            d="M1.56763 3.7C1.56763 3.26 1.92763 2.9 2.36763 2.9H17.1756C17.6156 2.9 17.9756 3.252 17.9756 3.7C17.9756 4.14 17.6156 4.5 17.1756 4.5H2.35963C1.91963 4.5 1.56763 4.148 1.56763 3.7ZM3.16763 1.3C3.16763 0.86 3.52763 0.5 3.96763 0.5H15.5756C16.0156 0.5 16.3756 0.852 16.3756 1.3C16.3756 1.74 16.0156 2.1 15.5756 2.1H3.95963C3.51963 2.1 3.16763 1.748 3.16763 1.3ZM17.8796 6.908L1.64763 6.9L2.35963 15.9H17.1596L17.8796 6.908ZM0.687626 15.98L0.0476258 6.972C-0.0403741 6.068 0.647626 5.3 1.56763 5.3H17.9676C18.8796 5.3 19.5756 6.06 19.4876 6.972L18.8396 15.98C18.7516 16.828 18.0156 17.5 17.1676 17.5H2.35963C1.51163 17.5 0.775626 16.836 0.687626 15.98Z"
            fill="white"
        />
    </svg>
)
