export const FullHDIcon = ({ className }: { className?: string }) => (
    <svg
        viewBox="0 0 25 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <g id="4k 1" clip-path="url(#clip0_120_1179)">
            <g id="Group">
                <path
                    id="Vector"
                    d="M11.4699 0.46875V5.2321H12.8592V6.55504H11.4699V7.53096H8.62517V6.42291H1.54641C0.355473 6.42291 0.884808 5.2321 0.884808 5.2321C0.884808 5.2321 7.36817 0.53495 7.56663 0.46875H11.4699ZM8.65827 2.02326L4.55652 4.88442H8.65827V2.02326ZM13.3056 0.46875V7.53096H16.2333V4.60347L20.8972 7.53096H24.7676L18.5487 3.7103L24.3376 0.46875H20.6327L16.2002 3.06538V0.46875H13.3056Z"
                    fill="white"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_120_1179">
                <rect
                    width="24"
                    height="7.0656"
                    fill="white"
                    transform="translate(0.767578 0.466797)"
                />
            </clipPath>
        </defs>
    </svg>
)
