export const PlusIcon = ({ className }: { className?: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.267578 8C0.267578 3.58172 3.8493 0 8.26758 0C12.6859 0 16.2676 3.58172 16.2676 8C16.2676 12.4183 12.6859 16 8.26758 16C3.8493 16 0.267578 12.4183 0.267578 8ZM9.06758 4.8C9.06758 4.35817 8.70941 4 8.26758 4C7.82575 4 7.46758 4.35817 7.46758 4.8V7.2H5.06758C4.62575 7.2 4.26758 7.55817 4.26758 8C4.26758 8.44183 4.62575 8.8 5.06758 8.8H7.46758V11.2C7.46758 11.6418 7.82575 12 8.26758 12C8.70941 12 9.06758 11.6418 9.06758 11.2V8.8H11.4676C11.9094 8.8 12.2676 8.44183 12.2676 8C12.2676 7.55817 11.9094 7.2 11.4676 7.2H9.06758V4.8Z"
            fill="white"
        />
    </svg>
)
