export enum SPORTS {
    CHANNELS = 'Channels',
    SOCCER = 'Soccer',
    TENNIS = 'Tennis',
    NFL = 'NFL',
    NBA = 'NBA',
    NHL = 'NHL',
    MLB = 'MLB',
    CFL = 'CFL',
    TOUR_DE_FRANCE = 'Tour de France',
    BASKETBALL = 'Basketball',
    EUROLEAGUE = 'Euroleague',
    F1 = 'F1',
    BOXING = 'Boxing',
    MMA = 'MMA',
    NCAAB = 'NCAAB',
    NCAA_BASEBALL = 'NCAA Baseball',
    NCAAF = 'NCAAF',
    GOLF = 'Golf',
    RUGBY = 'Rugby',
    MOTORSPORTS = 'Motorsports',
    ATHLETICS = 'Athletics',
    ICE_HOCKEY = 'Ice Hockey',
    HORSE_RACING = 'Horse Racing',
    VOLLEYBALL = 'Volleyball',
    HANDBALL = 'Handball',
    WATER_POLO = 'Water Polo',
    OLYMPIC_GAMES = 'Olympic Games',
    OTHER = 'Other',
}
