export const NoBlackout = ({ className }: { className?: string }) => (
    <svg
        viewBox="0 0 21 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <g id="Frame" clip-path="url(#clip0_120_1155)">
            <g id="Group">
                <path
                    id="Vector"
                    d="M6.13316 0.347665C6.61562 0.357906 6.89554 0.545656 7.09637 0.889297C8.49085 3.27487 9.89101 5.65703 11.29 8.03975C12.8865 10.7599 14.4841 13.4794 16.0805 16.1995C16.3263 16.6188 16.3149 16.9767 16.0492 17.309C15.6282 17.8347 14.8272 17.747 14.4545 17.1246C13.988 16.3446 13.5322 15.5577 13.072 14.7732C10.5476 10.4725 8.02318 6.17192 5.49765 1.87243C5.34745 1.6164 5.27861 1.35014 5.34062 1.05657C5.43791 0.601413 5.75652 0.345958 6.13316 0.347665Z"
                    fill="white"
                />
                <path
                    id="Vector_2"
                    d="M2.60233 12.1891C2.69905 12.1891 2.78268 12.1891 2.86631 12.1891C4.99529 12.1891 7.12427 12.1885 9.25325 12.1902C9.38752 12.1902 9.52578 12.1879 9.65549 12.2169C10.1067 12.3176 10.3479 12.7022 10.3024 13.2245C10.2648 13.6564 9.94679 13.9664 9.50757 13.9977C9.45124 14.0017 9.39435 14.0028 9.33746 14.0034C7.10891 14.0034 4.88094 14.0063 2.65239 14.0023C1.73867 14.0006 1.03262 13.4595 0.827228 12.6067C0.784558 12.4291 0.770903 12.2408 0.770334 12.0576C0.766352 10.0163 0.766352 7.97547 0.768628 5.93411C0.769765 4.7638 1.53442 3.9963 2.69961 3.99345C3.40909 3.99174 4.11912 3.9889 4.82859 3.99459C5.49824 3.99971 5.87374 4.46795 5.74402 5.12394C5.6638 5.5313 5.35373 5.79529 4.90938 5.80212C4.22096 5.81236 3.53255 5.80667 2.84413 5.80724C2.76789 5.80724 2.69108 5.80724 2.60176 5.80724C2.60233 7.93622 2.60233 10.0453 2.60233 12.1891Z"
                    fill="white"
                />
                <path
                    id="Vector_3"
                    d="M18.9338 12.1894C18.9338 10.049 18.9338 7.93939 18.9338 5.80756C18.8388 5.80756 18.7551 5.80756 18.6721 5.80756C16.5357 5.80756 14.3993 5.80813 12.2629 5.80643C12.1287 5.80643 11.9904 5.80586 11.8618 5.774C11.4192 5.66476 11.1888 5.28926 11.2326 4.77437C11.2696 4.34254 11.5876 4.03076 12.0257 3.99947C12.0894 3.99492 12.1531 3.99378 12.2174 3.99378C14.4317 3.99378 16.6466 3.99207 18.8609 3.99435C19.7946 3.99549 20.4995 4.52745 20.7072 5.38996C20.7498 5.56747 20.7641 5.75579 20.7646 5.93899C20.7686 7.98035 20.7686 10.0217 20.7663 12.0636C20.7652 13.234 19.9994 14.0003 18.8331 14.0037C18.2721 14.0054 17.7117 14.0089 17.1507 14.0026C16.4657 13.9946 16.0731 13.461 16.2609 12.8016C16.365 12.4352 16.6597 12.203 17.0671 12.1951C17.6064 12.1843 18.1458 12.1911 18.6851 12.1899C18.7625 12.1894 18.8393 12.1894 18.9338 12.1894Z"
                    fill="white"
                />
                <path
                    id="Vector_4"
                    d="M15.3011 9.90291C14.9893 9.90291 14.6775 9.9103 14.3658 9.9012C13.8349 9.88584 13.4964 9.53253 13.4942 9.00341C13.4919 8.46405 13.8327 8.10278 14.3743 8.09424C14.9979 8.08457 15.6214 8.08457 16.245 8.09367C16.7906 8.10221 17.1251 8.45723 17.1234 9.0017C17.1217 9.54334 16.7849 9.88982 16.2365 9.90234C15.9247 9.90973 15.6129 9.90348 15.3011 9.90348C15.3011 9.90348 15.3011 9.90347 15.3011 9.90291Z"
                    fill="white"
                />
                <path
                    id="Vector_5"
                    d="M6.21241 9.90298C5.90063 9.90298 5.58885 9.91037 5.27707 9.90127C4.7434 9.88534 4.41285 9.53715 4.41114 9.00177C4.40943 8.46469 4.73885 8.10512 5.26853 8.09545C5.90575 8.08407 6.5441 8.08407 7.18132 8.09545C7.70702 8.10512 8.04269 8.47209 8.04042 9.00348C8.03814 9.52463 7.70474 9.88192 7.18985 9.89956C6.86441 9.91037 6.53784 9.90184 6.21241 9.90184C6.21241 9.90241 6.21241 9.90298 6.21241 9.90298Z"
                    fill="white"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_120_1155">
                <rect
                    width="20"
                    height="17.3055"
                    fill="white"
                    transform="translate(0.767578 0.347656)"
                />
            </clipPath>
        </defs>
    </svg>
)
