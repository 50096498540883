import { modalItems } from '../assets/constants/modalItems'
import { AUTH_LOGIN_URL, AUTH_SIGNUP_URL } from '../constant/auth'
import React from 'react'
import { PlusIcon } from '../assets/icons/PlusIcon'

const TrialOverModal = () => {
    return (
        <div className="flex rounded-lg">
            <div className="bg-[#19AEB780] hidden md:flex flex-col w-[330px] xl:w-[500px] md:p-4 xl:p-8">
                <span className="text-white text-2xl xl:text-[40px] font-poppinsBold text-center my-6 xl:my-12">
                    Unlimited Access
                </span>
                <div className="flex flex-col gap-4">
                    {modalItems.map((item) => (
                        <div
                            key={item.title}
                            className="flex items-center gap-4"
                        >
                            <div className="w-[48px] h-[48px] min-w-[48px] min-h-[48px] bg-darkBlueOpacity0 rounded-full flex items-center justify-center">
                                <div className="w-[30px] h-[20px] rounded-full flex items-center justify-center ">
                                    {item.icon('w-[30px] h-[20px]')}
                                </div>
                            </div>
                            <span className="text-white text-base xl:text-xl font-poppinsRegular">
                                {item.title}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
            <div className="bg-white p-4 xl:py-10 xl:px-12 w-[330px] xl:w-[500px] flex flex-col justify-between items-center">
                <p className="m-0 flex flex-col">
                    <span className="text-black text-2xl xl:text-[40px] my-6 font-poppinsBold w-full text-center xl:leading-[45px]">
                        Your trial is over!
                    </span>
                    <span className="text-black font-poppinsRegular text-sm xl:text-xl text-center my-5 xl:my-10">
                        In order to keep using our service, please log in or
                        create an account
                    </span>
                </p>
                <a
                    href={AUTH_SIGNUP_URL}
                    className="bg-[#19AEB7] h-[42px] px-4 flex gap-2 items-center text-white xl:text-[16px] text-[14px] font-poppinsBold rounded-lg"
                >
                    <div className="lg:w-[18px] lg:h-[18px] w-[16px] h-[16px] flex items-center justify-center">
                        <PlusIcon className="lg:w-[18px] lg:h-[18px] w-[16px] h-[16px] mr-2" />
                    </div>
                    Create account
                </a>
                <div className="flex items-center justify-center mt-3 xl:mt-6">
                    <span className="text-black whitespace-nowrap">
                        Have an account already?
                    </span>
                    &nbsp;
                    <a
                        href={AUTH_LOGIN_URL}
                        className="text-sm text-[#19AEB7] font-poppinsBold  w-full text-center no-underline hove:underline"
                    >
                        Log in
                    </a>
                </div>
            </div>
        </div>
    )
}

export default TrialOverModal
