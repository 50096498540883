import { FullHDIcon } from '../icons/trialModal/FullHDIcon'
import { AllPlatforms } from '../icons/trialModal/AllPlatforms'
import { NoBlackout } from '../icons/trialModal/NoBlackout'
import { Unlimited } from '../icons/trialModal/Unlimited'

export const modalItems = [
    {
        title: 'Unlimited access to Soccer, NFL, NBA, NHL, MLB, Boxing, MMA, Tennis',
        icon: (className?: string) => <Unlimited className={className} />,
    },
    {
        title: 'HD Streaming - 1080, 4K',
        icon: (className?: string) => <FullHDIcon className={className} />,
    },
    {
        title: 'No Blackouts',
        icon: (className?: string) => <NoBlackout className={className} />,
    },
    {
        title: 'All Platforms',
        icon: (className?: string) => <AllPlatforms className={className} />,
    },
]
