import { QueryClient } from '@tanstack/react-query'
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retryOnMount: false,
            retry: false,
        },
    },
})
