export const Unlimited = ({ className }: { className?: string }) => (
    <svg
        viewBox="0 0 27 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            id="Vector"
            d="M6.60183 0.00379668C8.2882 0.0290349 9.70765 0.627868 10.8839 1.80718C13.2448 4.17422 15.6027 6.54508 17.9705 8.90523C19.507 10.4371 21.8518 10.5648 23.5175 9.22721C24.8965 8.11979 25.4203 6.23916 24.8146 4.57038C24.212 2.90925 22.6266 1.81712 20.8614 1.84466C19.7502 1.86225 18.8011 2.26988 18.0064 3.04232C17.6363 3.40178 17.2783 3.77347 16.9112 4.13598C16.4998 4.54208 15.9285 4.56273 15.5606 4.19028C15.192 3.81706 15.2065 3.23887 15.6249 2.83889C16.2589 2.23241 16.8508 1.55939 17.5636 1.06304C21.2002 -1.46614 26.215 0.764001 26.8329 5.15392C27.1901 7.6915 25.906 10.1717 23.6346 11.3289C21.3562 12.4898 18.6022 12.0952 16.7751 10.2918C14.3614 7.90947 11.9814 5.49349 9.58146 3.09815C8.11 1.62975 5.90433 1.42402 4.24244 2.58574C2.27921 3.95855 1.87922 6.72098 3.3744 8.58249C4.88945 10.4685 7.65648 10.6811 9.42162 9.03984C9.83385 8.65667 10.2201 8.24445 10.6216 7.84829C11.0323 7.44295 11.6028 7.4223 11.9722 7.79475C12.3408 8.16644 12.3171 8.73698 11.9095 9.1492C11.4927 9.56984 11.0904 10.0081 10.6468 10.3989C8.87862 11.9575 6.84044 12.4049 4.62866 11.6233C2.40464 10.8371 1.06395 9.20274 0.712148 6.85712C0.205855 3.47979 2.54689 0.463438 5.93952 0.0405068C6.16589 0.0122094 6.3961 0.0145038 6.60183 0.00379668Z"
            fill="white"
        />
    </svg>
)
